.video-container {
    overflow: hidden;
    position: relative;
    width:80%;
    margin-left:auto;
    margin-right:auto;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.xmas-container {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size:cover;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
}

@font-face {
    font-family: 'FinkHeavy';
    src: url('https://d18b95kkdrjsj9.cloudfront.net/kat-xmas-2021/FinkHeavy.woff2') format('woff2'),
        url('https://d18b95kkdrjsj9.cloudfront.net/kat-xmas-2021/FinkHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

.ac-title {
    margin-top: 40px;
    font-family: 'FinkHeavy'; 
    font-weight: 900; 
    font-style: normal;
    font-size: xxx-large;
    color: white;
    text-shadow: 3px 3px black;
    z-index:-100;
}
